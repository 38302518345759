.firstContainer {
  min-height: 100vh;
}

.tooltip {
  height: 75px;
  width: 75px;
  margin: 0 auto;
}

.center {
  text-align: center;
}

.StockCard {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.25s ease;
  border-radius: 7px;
}
.StockCard:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5);
  transform: translate(0px, -7px);
}
